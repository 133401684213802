.partner-form-head {
    ont-size: 18px;
    line-height: 24px;
    color: var(--color-light-black)
}

.form-head-border-main {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-top: 20px;
}

.form-head-border1 {
    height: 2px;
    width: 25px;
    background-color: var(--color-main);
}

.form-head-border2 {
    height: 2px;
    width: 100px;
    background-color: #ddd;
}

.partner-form-head {
    font-size: 18px;
    line-height: 24px;
    color: var(--color-light-black);
    font-weight: 600;
}

.partner-form-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    margin-bottom: 35px;
}

.partner-form-inner-flex {
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #eee;
    min-width: 48%;
}

@media screen and (max-width:675px) {
    .partner-form-inner-flex {
        width: 100%;
    }
}

/* animation Purpose */

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInRight {
    animation-name: fadeInRight;
}