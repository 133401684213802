.testi-main {
    width: 100%;
    height: 100;
    margin-top: 80px;
}

.testi-head-con {
    max-width: 700px;
    margin: 0 auto;

}


/*
=============== 
Slider
===============
*/
.title {
    text-align: center;
    margin-bottom: 2rem;
}

.title h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.title span {
    font-size: 0.85em;
    color: var(--clr-primary-5);
    margin-right: 1rem;
    font-weight: 700;
}

.section-center1 {
    margin: 0 auto;
    margin-top: 4rem;
    width: 80vw;
    height: 330px;
    max-width: 800px;
    text-align: center;
    position: relative;
    display: flex;
    overflow: hidden;
}

.personn-img {
    /* border-radius: 50%; */
    margin-bottom: 1rem;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 4px solid var(--clr-grey-8);
    box-shadow: var(--dark-shadow);
}

aside h5 {
    color: var(--color-main);
    font-weight: 600;
    text-align: left;
    margin-top: 40px;
}

.title {
    text-transform: capitalize;
    margin-bottom: 0.75rem;
    color: var(--clr-grey-3);
}

.textt {
    ine-height: 28px;
    font-size: 16px;
    font-style: italic;
    text-align: left;
    font-family: var(--base-font);

}

.icon {
    font-size: 3rem;
    margin-top: 1rem;
    color: var(--clr-primary-5);
}

.prevv,
.nextt {
    position: absolute;
    top: 200px;
    transform: translateY(-50%);
    background: var(--color-main);
    color: var(--color-white);
    width: 2rem;
    height: 2rem;
    display: grid;
    place-items: center;
    border-color: transparent;
    font-size: 1rem;
    border-radius: var(--radius);
    cursor: pointer;
    transition: var(--transition);
}

/* .prevv:hover,
.nextt:hover {
    background: var(--clr-primary-5);
} */

.prevv {
    left: 0;
    top: 35%;
}

.nextt {
    right: 0;
    top: 35%;
}

.testi-mainn {
    max-width: 675px !important;
    margin: 0 auto !important;
}

@media (min-width: 800px) {
    .textt {
        max-width: 45em;
    }

    .prevv,
    .nextt {
        width: 2rem;
        height: 2rem;
        font-size: 1.5rem;
    }
}

aside {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: var(--transition);
}

aside.activeSlide {
    opacity: 1;
    transform: translateX(0);
}

aside.lastSlide {
    transform: translateX(-100%);
}

aside.nextSlide {
    transform: translateX(100%);
}

.testi-slider-flex {
    display: flex !important;
    flex-direction: row !important;
    gap: 25px;
    background-color: #fff;
    box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, .1);
    /* margin: 30px 100px; */
    padding: 40px;
}

.testi-slider-imgbox {
    width: 200px;
    min-width: 200px;
}

.quote-icon {
    margin-right: 15px;
    font-size: 30px;
    color: var(--color-grey);
    position: absolute;
    top: -33px;
    left: -35px;
}

.quote-icon2 {
    margin-right: 15px;
    font-size: 30px;
    color: var(--color-grey);
    position: absolute;
    bottom: 40px;
    right: 0;
}

.testi-slider-content {
    width: 500px;
    position: relative;
}

.slick-prev {
    left: 0px !important;
    z-index: 11;
}

.slick-prev:before,
.slick-next:before {
    color: var(--color-main);
}

.slick-prev:before,
.slick-next:before {
    opacity: 1 !important;
}

.slick-prev,
.slick-next {
    top: 25% !important;
}

.slick-next {
    right: 15px !important;
}

@media screen and (max-width:920px) {
    .testi-slider-flex {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width:720px) {
    .quote-icon {
        top: -32px;
        left: 0px;
    }

    .nextt {
        top: 45%;
    }

    .prevv {
        top: 45%;
    }

    .section-center1 {
        width: 95vw;
    }

    .testi-slider-content {
        width: 100%;
    }
}


@media screen and (max-width:620px) {
    .testi-slider-flex {
        display: flex !important;
        flex-direction: column !important;
    }

    .slick-prev,
    .slick-next {
        top: 40% !important;
    }
}


@media screen and (max-width:420px) {
    .section-center1 {
        height: 535px;
    }

    .slick-prev {
        left: -15px !important;
        z-index: 11;


    }

}