.induapp-main {
    width: 100%;
    height: 100%;
    margin-top: 80px;
    margin-bottom: 80px;
}

.induapp-con {
    max-width: 1140px;
    margin: 0 auto;
}

.mx-700 {
    max-width: 700px;
    margin: 0 auto;
    font-size: 14px;
}

.induapp-card-main {
    width: 325px;
    overflow: hidden;
    border-radius: 4px;
    -webkit-transition: all 0.8s;
    position: relative;
    /* margin-top: 40px; */
}

.induapp-img {
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.8s;
    border-radius: 4px;

}

.induapp-cards-main {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
    margin-top: 40px;
}

.induapp-card-main:hover {
    scale: 1.1;
}

.induapp-txtbox {
    width: 95%;
    background-color: var(--color-white);
    margin-left: 10px;
    margin-right: 10px;
    position: absolute;
    top: 75%;
    left: 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.induapp-txt {
    font-size: 14px;
    color: var(--color-light-black);
    font-weight: 600;
    padding: 10px;
    margin-bottom: 0px;
    -webkit-transition: all 0.8s;
}

.induapp-txt:hover {
    color: var(--color-main);
    cursor: pointer;
}

.induapp-card-bgclr {
    background-color: rgba(0, 0, 0, .603);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.8s;
    /* opacity: 0.5 */
}


/* Media Query */

@media screen and (max-width: 1015px) {
    .induapp-cards-main {
        justify-content: center;
        align-items: center;
    }

    .induapp-card-bgclr {
        height: 98%;
    }

}