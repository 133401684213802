.card-main {
    width: 100%;
    height: 100%;
}

.card-flex {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    transform: translate(0px, -45px);
    flex-wrap: wrap;
}

.hero-card {
    max-width: 375px;
    background-color: #ffffff;
    padding: 50px 33px 50px;
    -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    transition: all 0.5s;
    position: relative;

}

.hero-card:hover .hero-card-img {
    transform: rotate(360deg)
}

.hero-card:hover .home-card-line {
    height: 125px;
}

.hero-card:hover .home-card-line2 {
    width: 125px;
}

.hero-card:hover .home-card-last-card-line {
    height: 125px;
}

.hero-card:hover .home-card-last-card-line2 {
    width: 125px;
}


.hero-card-dark {
    max-width: 375px;
    background-color: #1b1b1b;
    padding: 50px 33px 50px;
    -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 15px #1b1b1b;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.home-card-line {
    background-color: #75be42;
    width: 3px;
    height: 150px;
    position: absolute;
    left: 0;
    bottom: 0;
}

.home-card-line2 {
    background-color: #75be42;
    width: 150px;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
}

.home-card-last-card-line {
    background-color: #75be42;
    width: 3px;
    height: 150px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.home-card-last-card-line2 {
    background-color: #75be42;
    width: 150px;
    height: 3px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.home-card-head {
    font-size: 18px;
    color: #414141;
    font-weight: 700;
    text-transform: capitalize;
    /* margin: 26px 0 13px; */
}

.home-card-txt {
    font-size: 16px;
    color: #777777;
    line-height: 29px;
    text-align: center;
}

.home-card-head-dark {
    font-size: 18px;
    color: #ffffff;
    font-weight: 700;
    text-transform: capitalize;
    /* margin: 26px 0 13px; */
}

.home-card-txt-dark {
    font-size: 16px;
    color: #ffffff;
    line-height: 29px;
    text-align: center;
}

.hero-card-img {
    width: 75px;
    height: 75px;
}


/* Media Query */

@media screen and (max-width:1050px) {
    .card-flex {
        justify-content: center;
    }
}

@media screen and (max-width:420px) {

    .hero-card {
        max-width: 335px;
    }

    .hero-card-dark {
        max-width: 335px;
    }
}