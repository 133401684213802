.ourvalues-main {
    width: 100%;
    height: 100;
    margin-top: 80px;
    margin-bottom: 80px;
}

.ourvalues-con {
    max-width: 1140px;
    margin: 0 auto;
}

.mx-700 {
    max-width: 700px;
    margin: 0 auto;
}

.ourvalues-card-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}

.ourvalues-card {
    padding: 50px 30px;
    background: #fff;
    margin-bottom: 30px;
    box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, .05);
    /* z-index: 1; */
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: var(--color-black);
    transition: all 0.8s;
}

.ourvalues-card:hover {
    background-color: var(--color-main);
    color: var(--color-white) !important;
}

.ourvalues-card:hover .ourvalues-icon {
    color: #fff;
}

.ourvalues-card-icon-flex {
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.ourvalues-icon {
    width: 60px;
    height: 60px;
    color: var(--color-main);
}

.ourvalues-digit {
    color: #eee;
    font-size: 100px;

    line-height: 80px;
    font-weight: 700;
    color: #000;
    opacity: .05;
    transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
    margin-bottom: 0px;
}

.ourvalues-card-head {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
}

.ourvalues-card-txt {
    font-size: 16px;
    line-height: 28px;
}

.ourvalues-card-long {
    width: 47%
}

.ourvalues:hover .ourvalues-icon {
    color: #eee !important;
}

@media screen and (max-width:850px) {
    .ourvalues-card {
        width: 340px;
    }

    .ourvalues-card-flex {
        justify-content: center;
    }
}