.ruberconv-info-main {
    width: 100%;
    height: 100%;
    margin-top: 80px;
    margin-bottom: 80px;
}

.ruberconv-con {
    max-width: 1140px;
    margin: 0 auto;
}

.ruberconv-max-w-head-part {
    max-width: 700px;
    margin: 0px auto 40px auto;
}

.ruberconv-head {
    font-size: 36px;
    line-height: 45px;
    font-weight: 600;
    color: var(--color-light-black);
    text-align: center;
}

.ruberconv-info-flex {
    display: flex;
    gap: 2%;
}

.ruberconv-info-part1 {
    width: 38%;
    border: 2px dashed var(--color-main);
    border-radius: 14px;
    animation: 0.5s ease-in 0.5s both running fadeInLeft;
    overflow: hidden;
}


.ruberconv-img {
    width: 100%;
    height: 100%;
    padding: 10px;
    transition: all 0.5s;
    overflow: hidden;
}

.ruberconv-img:hover {
    scale: 1.1;
}

.ruberconv-info-par2 {
    width: 65%;
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
}

.ruberconv-part2-head {
    font-size: 24px;
    line-height: 45px;
    font-weight: 500;
    color: var(--color-light-black);
    margin-bottom: 0px;
}

.ruberconv-part2-txt {
    ont-size: 16px;
    font-weight: 400;
    color: #6f6f6f;
    line-height: 28px;
}

@media screen and (max-width:915px) {
    .ruberconv-info-flex {
        flex-direction: column;
        gap: 25px;
    }

    .ruberconv-info-part1 {
        width: 100%;
    }

    .ruberconv-info-par2 {
        width: 100%;
    }

    .ruberconv-info-flex {
        padding-left: 15px;
        padding-right: 15px;
    }
}

/* animation Purpose */


@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInLeft {
    animation-name: fadeInLeft;
}