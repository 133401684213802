.hero-main {
    width: 100%;
}

.slider-imgbox {
    max-width: 100%;
}

.hero-slider-img {
    width: 100%;
}

.hero-slider-icon {
    color: red;
    font-size: 20px;
}

/* Hero slider Start */

/* section */
/* .section {
  width: 90vw;
  margin: 5rem auto;
  max-width: var(--max-width);
} */

@media screen and (min-width: 992px) {
    /* .section {
    width: 95vw;
  } */
}

/*
=============== 
Slider
===============
*/
.title {
    text-align: center;
    margin-bottom: 2rem;
}

.title h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.title span {
    font-size: 0.85em;
    color: var(--clr-primary-5);
    margin-right: 1rem;
    font-weight: 700;
}

.section-center {
    /* margin: 0 auto; */
    /* margin-top: 4rem; */
    /* margin-top: 110px; */
    width: 100%;
    /* have to have a height */
    height: 85vh;
    /* max-width: 800px; */
    text-align: center;
    position: relative;
    display: flex;
    overflow: hidden;
}

.person-img {
    /* border-radius: 50%; */
    /* margin-bottom: 1rem; */
    width: 100%;
    height: 100%;
    min-height: 85vh;
    min-width: 100vw;
    object-fit: cover;
    /* border: 4px solid var(--clr-grey-8); */
    /* box-shadow: var(--dark-shadow); */
}

article h4 {
    text-transform: uppercase;
    color: var(--clr-primary-5);
    margin-bottom: 0.25rem;
}

.title {
    text-transform: capitalize;
    margin-bottom: 0.75rem;
    color: var(--clr-grey-3);
}

.text {
    max-width: 35em;
    margin: 0 auto;
    margin-top: 2rem;
    line-height: 2;
    color: var(--clr-grey-5);
}

.icon {
    font-size: 3rem;
    margin-top: 1rem;
    color: var(--clr-primary-5);
}

.prev,
.next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: var(--clr-grey-5);
    color: var(--color-white);
    width: 1.25rem;
    height: 1.25rem;
    display: grid;
    place-items: center;
    border-color: transparent;
    font-size: 1rem;
    border-radius: var(--radius);
    cursor: pointer;
    transition: var(--transition);
    /* background-color: red; */
}

.prev:hover,
.next:hover {
    background: var(--clr-primary-5);
}

.prev {
    left: 0;
}

.next {
    right: 20px;
}

.hero-slider-icon {
    font-size: 50px;
    color: var(--color-white);
}

@media (min-width: 800px) {
    .text {
        max-width: 45em;
    }

    .prev,
    .next {
        width: 2rem;
        height: 2rem;
        font-size: 1.5rem;
    }
}

article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: var(--transition);
}

article.activeSlide {
    opacity: 1;
    transform: translateX(0);
}

article.lastSlide {
    transform: translateX(-100%);
}

article.nextSlide {
    transform: translateX(100%);
}

/* Availaility */
.gift-main-container {
    /* padding: 1.5% 0; */
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;

    /* background-color: #11abad; */
    background-color: rgba(0, 0, 0, 0.349);

    /* background: transparent; */
}

.gift-container {
    max-width: 1140px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 45px;
    padding: 0 2%;
}

.hero-slider-big-txt {
    z-index: 6;
    min-width: 1000px;
    max-width: 1000px;
    font-weight: 600;
    white-space: normal;
    color: rgb(255, 255, 255);
    font-family: Poppins, sans-serif;
    visibility: inherit;
    transition: none 0s ease 0s;
    text-align: left;
    line-height: 75px;
    border-width: 0px;
    margin: 0px;
    padding: 0px 10px 0px 0px;
    letter-spacing: 2px;
    font-size: 65px;
    min-height: 0px;
    max-height: none;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
}

.hero-slider-small-txt {
    z-index: 7;
    min-width: 640px;
    max-width: 640px;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    font-family: Poppins, sans-serif;
    visibility: inherit;
    transition: none 0s ease 0s;
    text-align: left;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    letter-spacing: 0px;
    white-space: normal;
    min-height: 0px;
    max-height: none;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
}


.hero-slider-con-flex {
    display: flex;
    flex-direction: column;
    gap: 40px;
    animation: 1s ease-out 1s both running fadeInLeft;
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInLeft {
    animation-name: fadeInLeft;
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInRight {
    animation-name: fadeInRight;
}






/* Media Query */

@media (max-width: 920px) {
    .lbl-selector {
        min-width: 100px;
        max-width: 200px;
    }

    .gift-container {
        align-items: center;
        flex-wrap: wrap;
        padding-bottom: 10px;
    }

    .gift-part-flex {
        flex-wrap: wrap;
        justify-content: center;
    }

    .hero-slider-con-flex {
        margin-left: 15px;
    }
}

@media screen and (max-width: 875px) {
    .person-img {
        object-fit: revert;
    }

    .hero-slider-big-txt {
        font-size: 52px;
    }
}

@media screen and (max-width: 690px) {
    .person-img {
        object-fit: revert;
    }

    .hero-slider-big-txt {
        font-size: 42px;
        line-height: 55px;
        min-width: 530px;
    }

    .hero-slider-small-txt {
        min-width: 530px;
    }
}

@media screen and (max-width: 530px) {

    .hero-slider-big-txt {
        font-size: 36px;
        line-height: 48px;
        min-width: 350px;
    }

    .hero-slider-small-txt {
        min-width: 350px;
    }
}

@media screen and (max-width:420px) {

    .prev,
    .next {

        top: 46%;

    }
}

/* animation Purpose */

/* animation Purpose */


@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInLeft {
    animation-name: fadeInLeft;
}