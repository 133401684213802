.tarpaulin-conv-brand-card {
    width: 92% !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 100% !;
    border-radius: 6px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
    transition: all 0.8s;
    -moz-transition: all 0.8s;
    -ms-transition: all 0.8s;
    -o-transition: all 0.8s;
    -webkit-transition: all 0.8s;
    background-color: #dee2e6;
}