.conv-details-main {
    width: 100%;
    height: 100%;
    margin-top: 80px;
    margin-bottom: 80px;
}

.conv-details-con {
    max-width: 1140px;
    margin: 0 auto;
}

.conv-details-head-btns {
    display: flex;
    gap: 25px;
}

.details-btn {
    padding: 10px;
    color: var(--color-white);
    border: none;
    background-color: var(--color-main);
    transition: all 0.5s;
    margin-bottom: 40px;
    cursor: pointer;
}

.speci-main {
    width: 100%;
    /* margin-top: 40px;     */
}

.speci-list {
    line-height: 28px;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 400;
    color: #6f6f6f;
    margin-bottom: 0px;
}

.speci-lists {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.additionalinfo-main {
    width: 100%;
    margin-bottom: 40px;
}

.application-txt {

    line-height: 28px;
    color: #6f6f6f;
    font-size: 16px;
}

.application-txt {
    margin-top: 20px;
}



@media screen and (max-width: 1140px) {
    .conv-details-con {
        padding-left: 15px;
        padding-right: 15px;
    }

}

@media screen and (max-width: 510px) {
    .conv-details-head-btns {
        flex-direction: column;
    }

    .details-btn {
        margin-bottom: 0px;
    }

    .ruber-conv-brands-flex {
        flex-direction: column;
        padding-left: 15px;
        align-items: center;
    }

    .ruber-conv-brand-card {
        min-width: 200px !important;
    }

    .additionalinfo-main {
        margin-top: 40px;
        font-size: 14px;
    }

    .speci-main {
        margin-top: 25px;
    }

}