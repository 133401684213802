.ourvision-main {
    width: 100%;
    height: 100%;
    margin-top: 80px;
    display: flex;
    gap: 45px;
    /* margin-bottom: 80px; */
}

.ourvision-part1 {
    width: 72%;
    height: 100%;
    overflow: hidden;
}

.ourvision-img {
    width: 100%;
    height: 100%;
    transition: all 0.8s;
}

.ourvision-img:hover {
    scale: 1.2;
}

.ourvision-head {
    font-size: 45px;
    line-height: 55px;
    color: var(--color-black);
    font-weight: 400;
}

.ourvision-part2 {
    display: flex;
    width: 25%;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
}

.ourvision-txt {
    font-size: 16px;
    line-height: 28px;
}

@media screen and (max-width:975px) {
    .ourvision-main {
        flex-direction: column;
    }

    .ourvision-part1 {
        width: 100%;
    }

    .ourvision-part2 {
        width: 100%;
        padding-left: 15px;
    }

    .ourvision-head {
        font-size: 38px;
    }
}