.ourbrand-main {
    width: 100%;
    height: 500px;
    margin-top: 80px;
    position: relative;
}

.ourbrandbg {
    width: 100%;
    height: 100%;
    object-fit: revert;
}

.ourbrand-main-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.397);
}

.ourbrand-con {
    max-width: 1140px;
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 50px;
}

.ourbrand-main-head {
    color: var(--color-white);
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0px;
}

.heading-sec-flex {
    max-width: 700px;
    margin: 0 auto;
}

.ourbrand-txt {
    color: var(--color-white);
    font-size: 16px;
    margin-top: 20px;
    text-align: center;
}

.brandimgbox {
    width: 300px !important;
    background-color: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-top: 40px;
}

.brandimg {
    width: 100%;
    height: 100%;
}

.slick-prev {
    left: -65px !important;
}

.slick-prev,
.slick-next {
    transform: translate(0, 5%) !important;
}

.slick-prev:before,
.slick-next:before {
    font-size: 40px;
}

@media screen and (max-width:1050px) {
    .slick-slider {
        margin-left: 25px !important;
    }

    .brandimgbox {
        width: 250px !important;
    }
}

@media screen and (max-width:768px) {

    .brandimgbox {
        width: 200px !important;
    }
}

@media screen and (max-width:650px) {

    .ourbrand-main {
        min-height: 100%;
    }

    .ourbrandbg {
        min-height: 500px;
    }

    .brandimgbox {
        width: 150px !important;
    }
}

@media screen and (max-width:530px) {}