.footerup-main {
    width: 100%;
    height: 400px;
    margin-top: 160px;
    background-color: var(--color-main);
    position: relative;
}

.footerup-bg {
    width: 100%;
    height: 100%;
}

.footerup-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.footerup-inner-flex {
    max-width: 1140px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerup-txt {
    font-size: 36px;
    font-weight: 600;
    line-height: 50px;
    color: var(--color-white);
    max-width: 780px;
    text-align: center;
}

.footerup-headbox {
    max-width: 1140px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 10px 20px 10px rgba(0, 0, 0, .2);
    padding: 30px !important;
    background-color: var(--color-white);
    height: 125px;
    transform: translate(0px, -75px);

}

.footerup-headbox-main {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.footerup-headbox-txt {
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 5px;
    color: var(--color-light-black);
}

.footerup-headbox-subtxt {
    /* font-weight: 600;
    font-size: 30px; */
}

/* Media Query */

@media screen and (max-width:1140px) {
    .footerup-headbox {
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media screen and (max-width: 768px) {
    .footerup-txt {
        font-size: 30px;
        line-height: 40px;
    }
}

@media screen and (max-width: 550px) {
    .footerup-txt {
        font-size: 26px;
        line-height: 32px;
    }

    .footerup-headbox-txt {
        font-size: 24px;
    }
}

@media screen and (max-width: 420px) {
    .footerup-txt {
        font-size: 22px;
        line-height: 26px;
    }

    .footerup-headbox-txt {
        font-size: 18px;
    }

    .footerup-headbox {
        height: 150px;
    }
}