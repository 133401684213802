.sing-prod-slider-main {
    width: 100%;
    height: 500px;
    position: relative;
}

.sing-prod-slider-con {
    max-width: 1140px;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 50px;
}

.other-prod-bg {
    width: 100%;
    height: 100%;
}

.sing-prod-slider-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.63);
}

.sing-prod-slider-head {
    font-size: 36px;
    line-height: 45px;
    font-weight: 600;
    color: var(--color-white);
    text-align: center;
}

.sing-prod-slider-card {
    width: 315px !important;
}


.sing-prod-slider-bgclr {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.8s;
}

.sing-prod-slider-txtbox {
    width: 92%;
    background-color: var(--color-white);
    margin-left: 10px;
    margin-right: 10px;
    position: absolute;
    top: 75%;
    left: 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.sing-prod-slider-card {
    width: 250px !important;
}


/* @media screen and (min-width: 530px) {
    .sing-prod-slider-card {
        min-width: 350px !important;
    }
} */