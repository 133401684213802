.replpart-main {
    width: 100%;
    height: 100%;
    margin-top: 80px;
    margin-bottom: 80px;
}

.replpart-con {
    max-width: 1140px;
    margin: 0 auto;

}

.replpart-flex {
    display: flex;
    gap: 35px;
    margin-top: 40px;
}

.replpart-imgbox {
    width: 50%;
    border: dashed 2px var(--color-main);
    border-radius: 14px;
    background: var(--color-white);
    align-self: start;
    animation: 1s ease-in 1s both running fadeInLeft;
}

.replpart-img {
    width: 100%;
    height: 100%;
    padding: 10px;
}

.replpart-sec-part {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    animation: 1s ease-out 1s both running fadeInRight;
}

@media screen and (max-width: 975px) {
    .replpart-flex {
        flex-direction: column;
    }

    .replpart-imgbox {
        width: 100%;
    }

    .replpart-sec-part {
        width: 100%;
    }

    .replpart-con {
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media screen and (max-width: 420px) {
    .ourproduct-main-head {
        font-size: 28px;
    }
}


@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInLeft {
    animation-name: fadeInLeft;
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInRight {
    animation-name: fadeInRight;
}