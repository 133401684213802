.vvmpart-first-main {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 45px;
    margin-top: 80px;
}

.vvmpart-first-flex-part {
    width: 50%;
    display: flex;
    justify-content: end;
}

.vvmpart-first-part-img {
    width: 70%;
    height: 100%;
    border-radius: 6px;
}

.vvmpart-first-flex-part2 {
    width: 50%;
    background-image: url(../../Assest/vvm-part-first-bg.png);
}

.txt-start {
    text-align: start;
    font-weight: 500;
    font-size: 32px;
}

.vvmpart-first-flex-part2-txt {
    line-height: 28px;
    margin-bottom: 24px
}

.vvmpart-first-flex-part2-inner {
    max-width: 550px;
}

@media screen and (max-width:975px) {
    .vvmpart-first-main {
        flex-direction: column;
        padding-left: 15px;
        padding-right: 15px;
    }

    .vvmpart-first-flex-part {
        width: 100%;
    }

    .vvmpart-first-flex-part2 {
        width: 100%;
    }

    .vvmpart-first-part-img {
        width: 100%;
    }

    .vvmpart-first-flex-part2-inner {
        width: 100%;
    }
}