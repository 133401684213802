.ruber-conv-brands-main {
    width: 100%;
    height: 100%;
    margin-bottom: 80px;
}

.ruber-conv-brands-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}

.ruber-conv-brand-card {
    width: 24% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 6px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
    transition: all 0.8s;
    -moz-transition: all 0.8s;
    -ms-transition: all 0.8s;
    -o-transition: all 0.8s;
    -webkit-transition: all 0.8s;
    background-color: #dee2e6;
}

.ruber-conv-brand-img {
    width: 100%;
    height: 125px;
}

.ruber-conv-brands-con {
    max-width: 1140px;
    margin: 0 auto;
}


@media screen and (max-width:1140px) {
    .ruber-conv-brands-flex {
        padding-left: 15px;
        padding-right: 15px;
    }
}