.additionalinfo-part1 {
    width: 35%;
    align-items: center;
    height: 100%;
    align-self: center;
}

.additionalinfo-part2 {
    width: 60%;

    height: 100%;
    align-self: center;
}

.additionalinfo-part-line {
    width: 1px;
    height: 30px;
    background-color: #6f6f6f;

}

.additionalinfo-con {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    border: 1px solid #6f6f6f;
    gap: 10px;

}