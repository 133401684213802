.ourstrength-main {
    width: 100%;
    height: 100;
    margin-top: 80px;
    margin-bottom: 80px;
}

.ourstrength-con {
    max-width: 1140px;
    margin: 0 auto;
}

.ourstrength-head {
    font-size: 35px;
    line-height: 45px;
    font-weight: 600;
    color: var(--color-main);
}

.ourstrength-clr-chng {
    font-size: 35px;
    line-height: 45px;
    font-weight: 600;
    color: var(--color-light-black);
}

.ourstrength-txt {
    line-height: 28px;
    font-size: 15px;
    color: #494949;
    margin-bottom: 30px;
}

.jus-start {
    justify-content: flex-start;
}

.ourstrength-imgbox {
    padding: 20px 20px 0;
    position: relative;

}

.ourstrength-img {
    width: 220px;
    height: 220px;
    box-shadow: rgba(0, 0, 0, .1) 0 0 30px 0;
    border-radius: 100%;
    border-width: 10px;
    border-style: solid;
    border-color: #fff;
    border-image: initial;
    transition: all .5s ease 0s;
}

.ourstrength-img:hover {
    border-color: #75be42 !important;
}

.ourstrength-imgbox-main {
    text-align: center;
    width: 270px !important;
    margin-right: 0px !important;
    animation: 1s ease-in 1s both running fadeInRight;

}

.ourstrength-img-iconbox {
    font-size: 40px;
    width: 70px;
    height: 70px;
    border-radius: 40px;
    position: absolute;
    bottom: 10px;
    line-height: 70px;
    right: 60px;
    background: #fff;
    color: var(--color-main);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
    display: flex;
    align-items: center;
    justify-content: center;
}

.ourstrength-img-icon {
    width: 30px;
    height: 30px;
    color: var(--color-main);
}

.ourstrength-slider-head {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: var(--color-light-black);
}

.ourstrength-slider-txt {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    color: var(--color-light-black);
}

@media screen and (max-width: 1140px) {
    .ourstrength-con {
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media screen and (max-width: 400px) {
    .ourstrength-imgbox-main {
        width: 310px !important;
    }

    .ourstrength-img {
        width: 270px;
        height: 270px;
    }
}

/* animation Purpose */

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInRight {
    animation-name: fadeInRight;
}