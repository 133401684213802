.about-award-main {
    width: 100%;
    height: 100%;
    margin-top: 80px;
    margin-bottom: 80px;
}

.about-award-con {
    max-width: 1140px;
    margin: 0 auto;
}

.about-award-head-txt {
    font-size: 15px;
    color: var(--color-light-blavk);
    line-height: 28px;
    text-align: center;
    margin-top: 20px;
}

.award-img-flex {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    justify-content: space-between;
}

.award-imgbox {
    width: 325px;

    -moz-box-shadow: 0 0 0 transparent;
    transition: all 0.8s;
    overflow: hidden;
}

.award-img {
    width: 100%;
    height: 100%;
    transition: all 0.8s;
}

.award-img:hover {
    scale: 1.2;
}

@media screen and (max-width:650px) {
    .award-img-flex {
        justify-content: center;
        gap: 20px;
    }
}